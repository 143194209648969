import { AssignCohortTeachers_CohortFragment } from "@generated/graphql";
import { EngagementStaffMap } from "@utils/withFragments/staffing";
import { AccountRoleBadge, SubjectBadge, Table } from "components/shared";
import { Icon } from "components/shared/Icon";
import { useMemo } from "react";
import { CellProps, Column } from "react-table";

type CohortStaffAssignment =
  AssignCohortTeachers_CohortFragment["staffAssignments"][number];

type CohortStaffAssignmentRow = {
  id: string;
  staffAssignment: CohortStaffAssignment;
};

type Props = {
  staffAssignments: CohortStaffAssignment[];
  engagementStaffMap: EngagementStaffMap;
  onRemove: (teacher: CohortStaffAssignment) => void;
  removeFilter?: (teacher: CohortStaffAssignment) => boolean;
};

export const CohortStaffAssignmentTable = ({
  staffAssignments,
  engagementStaffMap,
  removeFilter,
  onRemove,
}: Props) => {
  const getData: CohortStaffAssignmentRow[] = useMemo(
    () =>
      staffAssignments.map((sa) => ({
        id: `${sa.user.id}_${sa.cohortSubject}`,
        staffAssignment: sa,
      })),
    [staffAssignments]
  );

  return (
    <Table
      columns={getStaffAssignmentColumns(
        engagementStaffMap,
        onRemove,
        removeFilter
      )}
      dataName="Cohort Teachers"
      emptyStateIcon={<Icon icon="group" size={10} />}
      data={getData}
      className="min-h-0"
      showPagination={false}
    />
  );
};

const getStaffAssignmentColumns = (
  engagementStaffMap: EngagementStaffMap,
  onRemove: (teacher: CohortStaffAssignment) => void,
  removeFilter?: (teacher: CohortStaffAssignment) => boolean
): Column<CohortStaffAssignmentRow>[] => [
  {
    Header: "Teacher",
    Cell: ({
      row: {
        original: {
          staffAssignment: { user },
        },
      },
    }: CellProps<CohortStaffAssignmentRow>) => (
      <div className="flex flex-col">
        <span className="text-md font-medium text-gray-800">
          {user.fullName}
        </span>
        <span>{user.email}</span>
      </div>
    ),
  },
  {
    Header: "Account Role",
    Cell: ({
      row: {
        original: {
          staffAssignment: { user },
        },
      },
    }: CellProps<CohortStaffAssignmentRow>) => (
      <AccountRoleBadge accountRole={user.accountRole} />
    ),
  },
  {
    Header: "Cohort Subject",
    Cell: ({
      row: {
        original: {
          staffAssignment: { cohortSubject },
        },
      },
    }: CellProps<CohortStaffAssignmentRow>) => (
      <SubjectBadge subject={cohortSubject} />
    ),
  },
  {
    Header: " ",
    width: "w-5!",
    Cell: ({
      row: {
        original: { staffAssignment },
      },
    }: CellProps<CohortStaffAssignmentRow>) =>
      removeFilter && !removeFilter(staffAssignment) ? null : (
        <button onClick={() => onRemove(staffAssignment)}>
          <Icon
            icon="remove"
            size={6}
            color="text-gray-500 hover:text-rose-800"
          />
          <span className="sr-only">Remove</span>
        </button>
      ),
  },
];
