import {
  AddCohortModal_EngagementFragment,
  EditCohortModal_CohortFragment,
  GradeLevel,
  Language,
  VideoProvider,
} from "@generated/graphql";
import { normalizeDateFromUTCDateTime } from "@utils/dateTime";
import { capitalizeFirstLetter, lowerString } from "@utils/strings";
import { getInputStyle } from "@utils/styleStrings";
import { getEngagementStaffMap } from "@utils/withFragments/staffing";
import clsx from "clsx";
import { CohortScheduler } from "components/cohorts/CohortScheduler";
import {
  checkAllDraftEventsHaveAPrimaryTeacher,
  updateDraftEventsWithCohortAssignmentRoles,
} from "components/cohorts/CohortScheduler/helpers";
import { DraftEvent } from "components/cohorts/CohortScheduler/types";
import {
  Badge,
  ErrorBox,
  ErrorPopover,
  Input,
  Modal,
  SelectMenu,
  Tooltip,
} from "components/shared";
import { disabledDay } from "components/shared/Calendars/utils";
import { DatePickerInput } from "components/shared/DatePickerInput";
import { GradeLevelSelectMenu } from "components/shared/Inputs/SelectMenuOptions/GradeLevelSelectMenu";
import { AssignCohortTeachers } from "components/staffAssignments/AssignCohortTeachers";
import { cohortStaffAssignmentSelectAddOptions } from "components/staffAssignments/constants";
import { AssignCohortStaffAssignment } from "components/staffAssignments/types";
import { useAuth } from "contexts/AuthProvider";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { getScopedTeachers, validateCohortModalData } from "../helpers";

type Props = {
  endDate: Date;
  startDate: Date;
  language?: Language;
  isAddMode?: boolean;
  name?: string | null;
  hourlyTTRate: number;
  loadingQuery: boolean;
  errorMsg?: string | null;
  loadingMutation: boolean;
  isZoomProvider?: boolean;
  draftEvents: DraftEvent[];
  isTTRateInherited: boolean;
  allIsInitialized?: boolean;
  meetingRoom?: string | null;
  instructionLevel?: GradeLevel | null;
  staffAssignments: AssignCohortStaffAssignment[];
  engagement:
    | EditCohortModal_CohortFragment["engagement"]
    | AddCohortModal_EngagementFragment;
  onCancel: () => void;
  onSaveCohort: () => void;
  setName: (name: string) => void;
  setEndDate: (date: Date) => void;
  setStartDate: (date: Date) => void;
  setHourlyTTRate: (rate: number) => void;
  setLanguage: (language: Language) => void;
  setMeetingRoom: (meetingRoom: string) => void;
  setErrorMsg: (errorMsg: string | null) => void;
  setInstructionLevel: (grade: GradeLevel | null) => void;
  setDraftEvents: (draftEvents: DraftEvent[]) => void;
  setStaffAssignments: (sa: AssignCohortStaffAssignment[]) => void;
};
const languageOptions = Object.values(Language);

export const AddEditCohortModalBody = ({
  name,
  endDate,
  errorMsg,
  startDate,
  engagement,
  draftEvents,
  meetingRoom,
  hourlyTTRate,
  loadingQuery,
  loadingMutation,
  instructionLevel,
  staffAssignments,
  isTTRateInherited,
  isAddMode = true,
  isZoomProvider = false,
  allIsInitialized = true,
  language = Language.English,
  setName,
  onCancel,
  setEndDate,
  setLanguage,
  setErrorMsg,
  onSaveCohort,
  setStartDate,
  setDraftEvents,
  setMeetingRoom,
  setHourlyTTRate,
  setInstructionLevel,
  setStaffAssignments,
}: Props) => {
  const isEditMode = !isAddMode;
  const cancelButtonRef = useRef(null);
  const { isManagingMode } = useAuth();
  const { startDate: engStart, endDate: engEnd } = engagement;
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);

  const engagementStaffMap = useMemo(() => {
    return getEngagementStaffMap(engagement.staffAssignments);
  }, [engagement.staffAssignments]);

  const scopedTeacherIds = useMemo(() => {
    return getScopedTeachers(engagement.rosterRecords);
  }, [engagement]);

  const [
    allDraftEventsHaveAPrimaryTeacher,
    setAllDraftEventsHaveAPrimaryTeacher,
  ] = useState(false);

  /**
   * Whenever draftEvents changes, we also need to re-calculate if all draft
   * events have a primary teacher. So whether we need to do this process on
   * account of a change to draftEvents (via onChangeDraftEvents()) or a change
   * to staffAssignments (via useEffect() below), we need to do this specific
   * combination of state updates.
   */
  const updateDraftEventsHelper = (
    newDraftEvents: DraftEvent[],
    newStaffAssignments: AssignCohortStaffAssignment[]
  ) => {
    const updatedDraftEvents = updateDraftEventsWithCohortAssignmentRoles(
      newDraftEvents,
      newStaffAssignments
    );

    setAllDraftEventsHaveAPrimaryTeacher(
      checkAllDraftEventsHaveAPrimaryTeacher(updatedDraftEvents)
    );
    return updatedDraftEvents;
  };

  const subjectOptionsInitialIndex = useMemo(() => {
    if (draftEvents.length === 0) return undefined;
    const firstSubject = draftEvents[0]?.cohortSubject;
    const index = cohortStaffAssignmentSelectAddOptions.findIndex(
      ({ cohortSubject }) => cohortSubject === firstSubject
    );
    return index !== -1 ? index : undefined;
  }, [draftEvents]);

  const onChangeDraftEvents = (newDraftEvents: DraftEvent[]) =>
    setDraftEvents(updateDraftEventsHelper(newDraftEvents, staffAssignments));

  /**
   * When the Staff Assignments change we need to update the cohortAssignmentRoles
   * in each DraftEvent. With the updates made, we then need to check to see if
   * every DraftEvent has a Primary Teacher assigned and that check is recorded
   * in the allDraftEventsHaveAPrimaryTeacher state.
   */
  useEffect(() => {
    if (allIsInitialized)
      setDraftEvents(updateDraftEventsHelper(draftEvents, staffAssignments));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffAssignments]);

  useEffect(() => {
    const { newErrors, newWarnings, errorMsg } = validateCohortModalData(
      name,
      startDate,
      endDate,
      instructionLevel,
      draftEvents,
      isManagingMode,
      allDraftEventsHaveAPrimaryTeacher
    );
    setErrorMsg(errorMsg);
    setValidationErrors(newErrors);
    setValidationWarnings(newWarnings);
  }, [
    name,
    startDate,
    endDate,
    instructionLevel,
    draftEvents,
    staffAssignments,
    allDraftEventsHaveAPrimaryTeacher,
    isManagingMode,
    setErrorMsg,
  ]);

  const defaultStartDate = dayjs(
    isAddMode ? normalizeDateFromUTCDateTime(new Date(engStart)) : startDate
  );

  const defaultEndDate = dayjs(
    isAddMode ? normalizeDateFromUTCDateTime(new Date(engEnd)) : endDate
  );

  const showMeetingRoomInput =
    isEditMode || engagement.videoProvider === VideoProvider.Manual;

  return (
    <>
      <ErrorBox msg={errorMsg} className="mt-4" />
      <div className="pt-3">
        <div className="grid grid-cols-12 gap-x-6 gap-y-3">
          <Input
            required
            label="Name"
            id="cohort-name"
            value={name ?? ""}
            className={clsx("col-span-12 sm:col-span-4")}
            onChange={(e) => setName(e.target.value)}
          />

          <GradeLevelSelectMenu
            value={instructionLevel ?? GradeLevel.Unknown}
            className="col-span-12 sm:col-span-4"
            onSelect={(gradeLevel) => setInstructionLevel(gradeLevel)}
          />

          <SelectMenu
            labelText="Language"
            options={languageOptions.map((lang) => ({
              value: capitalizeFirstLetter(lowerString(lang)),
              id: lang,
            }))}
            onSelect={(option) => setLanguage(option.id)}
            initialIndex={languageOptions.indexOf(language)}
            listAlignment="left"
            className="col-span-12 sm:col-span-4"
          />

          <div className="col-span-12 sm:col-span-4">
            <DatePickerInput
              label="Start Date"
              required
              onChange={(date) => date && setStartDate(date?.toDate())}
              disabledDate={(date) =>
                disabledDay(date, engStart, engEnd, startDate, endDate, true)
              }
              defaultValue={defaultStartDate}
              style={{ ...getInputStyle }}
              showNow={false}
            />
          </div>

          <div className="col-span-12 sm:col-span-4">
            <DatePickerInput
              label="End Date"
              required
              onChange={(date) => date && setEndDate(date.toDate())}
              disabledDate={(date) =>
                disabledDay(
                  date,
                  engStart,
                  engEnd,
                  startDate,
                  endDate,
                  false,
                  isAddMode
                )
              }
              defaultValue={defaultEndDate}
              style={{ ...getInputStyle }}
              showNow={false}
            />
          </div>

          <Input
            id="tt-rate"
            label={
              <Tooltip
                disabled={!isTTRateInherited}
                content="This Cohort's TT Hourly Pay Rate is Inherited from its Engagement"
                className={clsx(
                  "flex items-center justify-between w-full gap-1",
                  isTTRateInherited ? "cursor-pointer" : "cursor-default"
                )}
              >
                TT Pay Rate ($/Hour)
                {isTTRateInherited && (
                  <Badge className="bg-slate-200 text-slate-900">
                    Inherited
                  </Badge>
                )}
              </Tooltip>
            }
            type="number"
            value={hourlyTTRate.toString()}
            onChange={(e) => setHourlyTTRate(e.target.valueAsNumber)}
            className={clsx(
              "col-span-12 sm:col-span-4",
              isTTRateInherited && "text-gray-400"
            )}
          />
          {showMeetingRoomInput && (
            <Input
              id="cohort-meeting-room"
              label="Meeting Room"
              value={meetingRoom ?? ""}
              onChange={(e) => setMeetingRoom(e.target.value)}
              className="col-span-12"
              disabled={isEditMode && isZoomProvider}
            />
          )}
        </div>

        <div className="flex flex-col gap-y-3 mt-4">
          <CohortScheduler
            draftEvents={draftEvents}
            onChange={onChangeDraftEvents}
            className="col-span-12 pt-0! mt-0"
            defaultTimeZone={
              engagement.timeZone || engagement.organization.timeZone
            }
          />

          <AssignCohortTeachers
            className="col-span-12"
            includedUserIds={scopedTeacherIds}
            staffAssignments={staffAssignments}
            engagementStaffMap={engagementStaffMap}
            optionsInitialIndex={subjectOptionsInitialIndex ?? undefined}
            staffAssignmentOptions={cohortStaffAssignmentSelectAddOptions}
            onAdd={(sa) => setStaffAssignments([...staffAssignments, sa])}
            onRemove={(sa) => {
              setStaffAssignments(
                staffAssignments.filter(
                  (csa) =>
                    !(
                      csa.cohortSubject === sa.cohortSubject &&
                      csa.user.id === sa.user.id
                    )
                )
              );
            }}
          />
        </div>

        <div
          className={clsx(
            "flex flex-col items-end",
            (validationErrors.length || validationWarnings.length) && "mt-4"
          )}
        >
          {validationErrors.length > 0 && (
            <ErrorPopover
              label="Errors present (Click to view)"
              header="Please fix the following errors:"
              issues={validationErrors}
              openOrientation="TOP-END"
              mode="error"
            />
          )}
          {validationWarnings.length > 0 && (
            <ErrorPopover
              label="Warnings present (Click to view)"
              header="Please review the following warnings:"
              issues={validationWarnings}
              openOrientation="TOP-END"
              mode="warning"
            />
          )}
        </div>

        <Modal.Buttons className="mt-3!">
          <Modal.Button
            type="confirm"
            onClick={onSaveCohort}
            disabled={validationErrors.length > 0 || loadingQuery}
            loading={loadingMutation}
          >
            Save
          </Modal.Button>
          <Modal.Button type="cancel" onClick={onCancel} ref={cancelButtonRef}>
            Cancel
          </Modal.Button>
        </Modal.Buttons>
      </div>
    </>
  );
};
