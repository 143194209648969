import {
  LocalizedWeekday,
  Weekday,
  WeekdaysCheck,
  weekdaysOrdered,
} from "@utils/dateTime";
import clsx from "clsx";
import { getDayName } from "components/cohorts/modals/helpers";

type WeekdaysCheckboxesProps = {
  updateWeekdayCheckbox: (index: number, checked: boolean) => void;
  draftWeekdaysCheck: WeekdaysCheck;
  required?: boolean;
  className?: string;
  localizedWeekdays?: LocalizedWeekday[];
  showLabel?: boolean;
};

export const WeekdaysCheckboxes = ({
  updateWeekdayCheckbox,
  draftWeekdaysCheck,
  localizedWeekdays,
  required = false,
  className = "",
  showLabel,
}: WeekdaysCheckboxesProps) => {
  const renderWeekdayCheckbox = (weekday: Weekday, index: number) => (
    <div
      className="flex flex-row h-9 flex-center gap-2 cursor-pointer"
      key={weekday}
      onClick={() => updateWeekdayCheckbox(index, !draftWeekdaysCheck[index])}
    >
      <label className="block text-sm font-medium text-gray-700 cursor-pointer">
        {getDayName(index, localizedWeekdays)}
      </label>
      <input
        className="h-4 w-4 rounded-sm border-gray-600 text-blue-500 focus:ring-blue-500 cursor-pointer"
        id={`weekday-${weekday}`}
        type="checkbox"
        title={String(weekday)}
        checked={draftWeekdaysCheck[index]}
        readOnly
      />
    </div>
  );

  return (
    <div className={clsx("flex flex-col gap-1", className)}>
      {showLabel && (
        <label className="block text-sm font-medium text-gray-700 ">
          Weekdays
          {required && (
            <span className="ml-1 text-gray-500 text-sm font-light">*</span>
          )}
        </label>
      )}
      <div className="grid w-full grid-cols-7">
        {weekdaysOrdered.map((weekday, index) =>
          renderWeekdayCheckbox(weekday, index)
        )}
      </div>
    </div>
  );
};
