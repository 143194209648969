import { IANAtzName, getLocalizedWeekdays } from "@utils/dateTime";
import clsx from "clsx";
import { Icon } from "components/shared";
import { EmptyStateContainer } from "components/shared/EmptyStateContainer";
import { useMemo, useState } from "react";
import { DraftEventEditor } from "./components/DraftEventEditor";
import { DraftEventsTable } from "./components/DraftEventsTable/DraftEventsTable";
import { isNewEventId } from "./helpers";
import { DraftEvent } from "./types";

type Props = {
  readOnly?: boolean;
  className?: string;
  draftEvents: DraftEvent[];
  defaultTimeZone?: IANAtzName;
  onChange: (draftEvents: DraftEvent[]) => void;
};

export function CohortScheduler({
  className,
  draftEvents,
  readOnly = false,
  defaultTimeZone = "",
  onChange,
}: Props) {
  const [editDraftEvent, setEditDraftEvent] = useState<DraftEvent | null>(null);

  const localizedWeekdays = useMemo(() => {
    return getLocalizedWeekdays("2000-01-01");
  }, []);

  const handleAddDraftEvent = (newDraftEvent: DraftEvent) => {
    const isNewDraftEvent = !draftEvents.some((e) => e.id === newDraftEvent.id);
    onChange(
      isNewDraftEvent
        ? [...draftEvents, newDraftEvent]
        : draftEvents.map((e) =>
            e.id === newDraftEvent.id ? newDraftEvent : e
          )
    );
    setEditDraftEvent(null);
  };

  const handleAddTeacherName = (
    id: DraftEvent["id"],
    teacherId: DraftEvent["teacherAssignedId"] | null
  ) => {
    const draftEvent = draftEvents.find((draftEvent) => {
      return draftEvent.id === id;
    });
    draftEvent?.teacherAssignedId !== teacherId &&
      onChange(
        draftEvents.map((event) =>
          draftEvent && event.id === draftEvent.id
            ? { ...draftEvent, teacherAssignedId: teacherId, dirty: true }
            : event
        )
      );
  };
  const handleSelectDraftEventToEdit = (id: DraftEvent["id"]) => {
    const cancelEdit = editDraftEvent && editDraftEvent.id === id;
    setEditDraftEvent(
      cancelEdit
        ? null
        : draftEvents.find((draftEvent) => draftEvent.id === id) ?? null
    );
  };

  const handleDeleteDraftEvent = (id: DraftEvent["id"]) => {
    onChange(
      isNewEventId(id)
        ? draftEvents.filter((draftEvent) => draftEvent.id !== id)
        : draftEvents.map((draftEvent) =>
            draftEvent.id === id
              ? { ...draftEvent, toRemove: !draftEvent.toRemove }
              : draftEvent
          )
    );
  };

  return (
    <div className={clsx("flex flex-col gap-2", className)}>
      <div className="flex flex-col gap-4">
        {!readOnly && (
          <DraftEventEditor
            initialDraftEvent={
              editDraftEvent ?? {
                timeZone: draftEvents[draftEvents.length - 1]?.timeZone,
              }
            }
            updateMode={editDraftEvent !== null}
            localizedWeekdays={localizedWeekdays}
            submitNewDraftEvent={handleAddDraftEvent}
            defaultTimeZone={defaultTimeZone}
          />
        )}
        {draftEvents.length > 0 ? (
          <DraftEventsTable
            draftEvents={draftEvents}
            readOnly={readOnly}
            editDraftEventId={editDraftEvent?.id}
            localizedWeekdays={localizedWeekdays}
            onEditDraftEvent={handleSelectDraftEventToEdit}
            onDeleteDraftEvent={handleDeleteDraftEvent}
            addTeacherToADraftEvent={handleAddTeacherName}
          />
        ) : (
          <EmptyStateContainer
            icon={<Icon icon="calendarDays" size={8} />}
            dataName="Events"
          />
        )}
      </div>
    </div>
  );
}
